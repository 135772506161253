
import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'
import {media} from '../../../utils'
import {ContentContainer} from '../../base'
import CounterItem from './CounterItem'


const StyledContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;

  ${media.down('mobile')(css`
    justify-content: center;
    flex-direction: column;
  `)}
`

const CounterList = ({children, ...props}) => (
  <ContentContainer transparent>
    <StyledContainer {...props}>
      {children}
    </StyledContainer>
  </ContentContainer>
)

CounterList.Item = CounterItem

CounterList.propTypes = {
  children: PropTypes.node.isRequired,
}

export default CounterList
