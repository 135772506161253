
import PropTypes from 'prop-types'
import styled, {css} from 'styled-components'
import {isEmpty, isNumber} from 'lodash-es'
import {useInView} from 'react-intersection-observer'
import CountUp from 'react-countup'
import {Circle} from '../../../assets/icons'
import {media} from '../../../utils'


const StyledRingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${({smallDescription}) => smallDescription ? '5rem 0.75rem' : '0 0.75rem'};
  height: 100%;
  color: white;

  ${media.down('mobile')(css`
    padding: 1rem 0;
  `)}
`

const StyledRing = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 13rem;
  height: 13rem;
`

const StyledText = styled.span`
  white-space: nowrap;
  font-family: 'Yeseva one', serif;
  font-size: 4rem;
  font-weight: ${({theme}) => theme.components.typography.fonts.heading};
`

const StyledDescription = styled.div`
  padding-top: 2rem;
  width: ${({smallDescription}) => smallDescription ? '50%' : '100%'};
  text-align: center;
  line-height: 1.5rem;
`

const StyledImage = styled(Circle)`
  position: absolute;
  transform: rotate(${({rotate}) => rotate}deg);
  z-index: -1;
  width: 95%;
  height: 95%;

  path,
  rect {
    fill: ${({theme}) => theme.colors.primary};
  }
`

const CounterItem = ({index, count, description, smallDescription, ...props}) => {
  const [ref, inView] = useInView({
    threshold: 0.3,
    triggerOnce: true,
  })

  return (
    <StyledRingContainer smallDescription={smallDescription} ref={ref} {...props}>
      <If condition={count}>
        <StyledRing>
          <StyledImage rotate={index * 40} />
          <Choose>
            <When condition={isNumber(count)}>
              <CountUp
                  useEasing
                  start={0}
                  end={inView ? count : 0}
                  duration={2}
                  separator=" "
              >
                {({countUpRef}) => (
                  <StyledText ref={countUpRef} />
                )}
              </CountUp>
            </When>
            <Otherwise>
              <StyledText>
                {count}
              </StyledText>
            </Otherwise>
          </Choose>
        </StyledRing>
      </If>
      <If condition={!isEmpty(description)}>
        <StyledDescription smallDescription={smallDescription}>
          {description}
        </StyledDescription>
      </If>
    </StyledRingContainer>
  )
}

CounterItem.defaultProps = {
  smallDescription: false,
}

CounterItem.propTypes = {
  index: PropTypes.number.isRequired,
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  description: PropTypes.object.isRequired,
  smallDescription: PropTypes.bool,
}

export default CounterItem
